<template>
  <LoginBase>
    <div>
      <FormHead
        class="pr-14"
        header="Enter your code"
        subheader=""
      />
      <validation-observer ref="form">
        <v-form
          @submit.prevent="confirmCode(code, rememberMe)"
        >
          <div class="login-text-input-header">
            Enter your two-factor authentication you received
          </div>
          <CustomTextInput
            v-model="code"
            class="mb-2 mt--2 hide-arrow"
            type="number"
            placeholder="Enter 6-digit security code"
            :required="true"
            @enter="confirmCode(code, rememberMe)"
          />
          <v-checkbox
            v-model="rememberMe"
            label="Remember me"
            hide-details
            class="mt-2"
          />
        </v-form>
      </validation-observer>

      <v-btn
        dense
        max-height="34px"
        block
        color="primary"
        class="mb-3 mt-2"
        @click="confirmCode(code, rememberMe)"
      >
        Confirm code
      </v-btn>
      <v-btn
        text
        block
        color="primary"
        @click="resendCode(type, phone)"
      >
        Resend code
      </v-btn>
      <v-btn
        text
        block
        color="primary"
        @click="returnBack()"
      >
        Return to Login Page
      </v-btn>
    </div>
  </LoginBase>
</template>

<script>
import FormHead from "@/views/Login/components/FormHead";
import LoginBase from "@/views/Login/LoginBase";
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "PhoneCodeConfirm",
  metaInfo: {
    title: 'Phone Code Confirm'
  },
  components: { FormHead, LoginBase, CustomTextInput },
  props: {
    type: {
      default: () => 'sms',
      type: String
    },
    phone: {
      default: () => '',
      type: String
    }
  },
  data: () => ({
    code: "",
    rememberMe: false,
    requestIsSent: false
  }),
  async created() {
    if (this.$store.getters["user/user_info"].twoFaExpirationDate === undefined) {
      const user_details = (await this.$rest.user.get_user_details()).data;
      localStorage.setItem('user', JSON.stringify(user_details));
      this.$store.commit('user/update_user_info', {
        ...user_details
      });
    }
    if (this.$store.getters["user/user_info"].twoFaExpirationDate
        && new Date().getTime() < new Date(this.$store.getters["user/user_info"].twoFaExpirationDate).getTime()) {
      localStorage.setItem("authenticated2fa", "true");
      await this.redirect();
    }
  },
  methods: {
    async validate() {
      return await this.$refs.form.validate();
    },
    async resendCode(type, phone) {
      await this.$rest.user.phoneValidation({
        type: type,
        phone: phone
      });

      this.$notifier.success("New code sent.");
    },
    async confirmCode(code, rememberMe) {
      if (!(await this.validate())) {
        this.requestIsSent = false;
        this.$notifier.error("Please check the form again.");
        return;
      }
      if (this.requestIsSent === false) {
        this.requestIsSent = true;
        try {
          const user_details = (await this.$rest.user.phoneCodeValidation({
            code: code,
            rememberMe: rememberMe,
            type: this.type,
            phone: this.phone
          })).data;
          this.$store.commit("user/update_user_info", {
            ...user_details,
          });
        } catch (e) {
          this.requestIsSent = false;
          return;
        }
      }
      this.$notifier.success("Successfully verified.");

      await this.redirect()
    },
    async redirect () {
      let redirectPageData = [];

      try {
        redirectPageData = JSON.parse(localStorage.getItem('redirectPage'));
      } catch (e) {
        redirectPageData = null;
      }

      localStorage.setItem("authenticated2fa", "true");

      if (localStorage.getItem('redirectPage') && localStorage.getItem('redirectPage') !== 'undefined' && redirectPageData && redirectPageData.value !== '/') {
        try {
          localStorage.removeItem('redirectPage');
          return await this.$router.push(redirectPageData.value);
        } catch (e) {
          await this.$router.push({name: 'Accounts'})
        }
      } else if (redirectPageData && redirectPageData.expiry < Date.now()) {
        localStorage.removeItem('redirectPage');
      }

      const respAccounts = await this.$rest.account.get_collection({
        limit: 5,
        page: 1,
        lastAccessedDate: true,
      });
      const accountTotalCount = respAccounts.data.totalCount;

      if (accountTotalCount === 1) {
        const firstAccount = respAccounts.data.items[0]
        const selectedAccount = await this.$store.dispatch('user/selectAccount', firstAccount.accountId, firstAccount.logoUrl);

        this.$notifier.success(`Successfully selected account ${selectedAccount.account.name}`)
        await this.$router.push(
            this.$route.query.next
                ? { path: this.$route.query.next }
                : {
                  name: "DashboardView",
                  params: { id: this.$store.getters["user/defaultDashboard"] },
                  query: {
                    accid: firstAccount.accountId,
                  },
                }
        );
      } else {
        await this.$router.push({name: 'Accounts'})
      }
    },
    async returnBack() {
      await this.$rest.login.logout();
      this.$store.commit('user/clearLogoutCheck');

      await this.$router.push({name: 'Login'});
    }
  }
};
</script>
<style lang="scss" scoped>
.login-text-input-header {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #241c15;
}

#btn-primary {
  background-color: #2b84eb !important;
}
</style>